<template>
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="bg" d="M17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5C34 26.6127 26.6127 34 17.5 34Z" stroke="#2B314F" stroke-width="2"/>
        <path id="telegram" d="M8.85928 17.1809C13.5569 15.1343 16.6894 13.785 18.2567 13.1331C22.7318 11.2717 23.6617 10.9484 24.2678 10.9376C24.4011 10.9354 24.6991 10.9684 24.8922 11.1251C25.0552 11.2573 25.1001 11.436 25.1215 11.5615C25.143 11.6869 25.1697 11.9726 25.1485 12.1958C24.906 14.7439 23.8566 20.9273 23.3228 23.7811C23.0969 24.9887 22.6521 25.3936 22.2216 25.4332C21.2858 25.5193 20.5752 24.8147 19.6688 24.2206C18.2506 23.2909 17.4494 22.7122 16.0727 21.805C14.4817 20.7566 15.5131 20.1803 16.4198 19.2386C16.6571 18.9922 20.7801 15.2419 20.8599 14.9017C20.8699 14.8592 20.8792 14.7006 20.7849 14.6168C20.6907 14.5331 20.5517 14.5617 20.4513 14.5845C20.3091 14.6168 18.0434 16.1143 13.6543 19.0771C13.0111 19.5187 12.4286 19.7338 11.9067 19.7226C11.3314 19.7101 10.2246 19.3973 9.40185 19.1298C8.3927 18.8018 7.59065 18.6283 7.66049 18.0712C7.69687 17.7811 8.09647 17.4843 8.85928 17.1809Z" fill="#2B314F"/>
    </svg>
</template>

<script>
export default {
    name: 'telegramIcon',

}
</script>

<style scoped>
.telegramIcon {
  width: 35px;
  height: 35px;
}
</style>
