<template>
  <div class="content-layout" @Click="isPlaying()">
    <loader
      v-show="isLoading" 
      :disableScrolling="!isLoading" 
      object="#2B314F" 
      color1="#2B314F" 
      color2="#2B314F" 
      size="5" 
      speed="3" 
      bg="#FDF4DB" 
      objectbg="#999793" 
      opacity="100" 
      name="circular"
    ></loader>
    <HeaderMain />
    <router-view />
    <FooterMain />
  </div>
</template>

<script>
import FooterMain from './components/Footer/FooterMain.vue'
import HeaderMain from './components/Header/HeaderMain.vue'

export default {
  components: {
    FooterMain,
    HeaderMain,
  },
  data() {
    return {
      isLoading: true

    }
  },
  computed: {},
  watch: {},
  methods: {
    showToggle(){
			setTimeout(() => {
				this.isLoading = false
			}, 6000)
		},
    
  },
  mounted() {
    this.showToggle();
  },
  created() {},
}
</script>