<template>
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="bg" d="M17.5 34C8.3873 34 1 26.6127 1 17.5C1 8.3873 8.3873 1 17.5 1C26.6127 1 34 8.3873 34 17.5C34 26.6127 26.6127 34 17.5 34Z" stroke="#2B314F" stroke-width="2"/>
        <path id="whatsapp" fill-rule="evenodd" clip-rule="evenodd" d="M23.625 11.2656C21.9844 9.625 19.7969 8.75 17.5 8.75C12.6875 8.75 8.75 12.6875 8.75 17.5C8.75 19.0312 9.18751 20.5625 9.95313 21.875L8.75 26.25L13.3438 25.0469C14.6563 25.7031 16.0781 26.1406 17.5 26.1406C22.3125 26.1406 26.25 22.2031 26.25 17.3906C26.25 15.0937 25.2656 12.9062 23.625 11.2656ZM17.5 24.7188C16.1875 24.7188 14.875 24.3906 13.7813 23.7344L13.5625 23.625L10.8281 24.3906L11.5938 21.7656L11.375 21.4375C10.6094 20.2344 10.2812 18.9219 10.2812 17.6094C10.2812 13.6719 13.5625 10.3906 17.5 10.3906C19.4688 10.3906 21.2188 11.1562 22.6406 12.4687C24.0625 13.8906 24.7188 15.6406 24.7188 17.6094C24.7188 21.4375 21.5469 24.7188 17.5 24.7188ZM21.4375 19.25C21.2188 19.1406 20.125 18.5938 19.9063 18.5938C19.6875 18.4844 19.5781 18.4844 19.4687 18.7031C19.3594 18.9219 18.9219 19.3594 18.8125 19.5781C18.7031 19.6875 18.5937 19.6875 18.375 19.6875C18.1562 19.5781 17.5 19.3594 16.625 18.5938C15.9688 18.0469 15.5313 17.2812 15.4219 17.0625C15.3125 16.8438 15.4219 16.7344 15.5313 16.625C15.6406 16.5156 15.75 16.4062 15.8594 16.2969C15.9688 16.1875 15.9688 16.0781 16.0781 15.9688C16.1875 15.8594 16.0781 15.75 16.0781 15.6406C16.0781 15.5312 15.6406 14.4375 15.4219 14C15.3125 13.6719 15.0938 13.6719 14.9844 13.6719C14.875 13.6719 14.7656 13.6719 14.5469 13.6719C14.4375 13.6719 14.2187 13.6719 14 13.8906C13.7812 14.1094 13.2344 14.6562 13.2344 15.75C13.2344 16.8438 14 17.8281 14.1094 18.0469C14.2187 18.1562 15.6406 20.4531 17.8281 21.3281C19.6875 22.0938 20.0156 21.875 20.4531 21.875C20.8906 21.875 21.7656 21.3281 21.875 20.8906C22.0938 20.3438 22.0938 19.9063 21.9844 19.9063C21.875 19.3594 21.6563 19.3594 21.4375 19.25Z" fill="#2B314F"/>
    </svg>
</template>

<script>
export default {
    name: 'whatsup',

}
</script>

<style scoped>
.telegramIcon {
  width: 35px;
  height: 35px;
}
</style>
