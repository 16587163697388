<template>
    <div class="footer">
        <div class="footer-wrapper">
            <img @click="scrollToHead" class="footer-logo" src="../../assets//icons/AltursLogo.svg" alt="">
            <div class="footer-main">
                <div class="footer-main_list">
                    <span class="footer-text_title">туры</span>
                    <div @click="scrollToTop1" class="link">
                        <span class="footer-text">Групповые туры</span>
                    </div>
                    <div @click="scrollToTop2" class="link">
                        <span class="footer-text">Индивидуальный тур</span>
                    </div>
                </div>
                <div class="footer-main_list">
                    <span class="footer-text_title">О компании</span>
                    <div @click="scrollToTop3" class="link">
                        <span class="footer-text">О нас</span>
                    </div>
                    <a href="#" class="link">
                        <span class="footer-text_policy">Политика конфиденциальности</span>
                    </a>
                </div>
                <div class="footer-main_list">
                    <span class="footer-text_title">Cоцсети</span>
                    <a href="https://t.me/al_tours" class="link">
                        <span class="footer-text">Telegram</span>
                    </a>
                </div>
                <div class="footer-main_list">
                    <span class="footer-text_title">контакты</span>
                    <span class="footer-text">+7 993 953 82 23</span>
                    <span class="footer-text">info@al-tours.ru</span>
                    <div class="footer-main_wrapper">
                        <a href="https://t.me/al_tours" class="footer-main_social">
                            <telegram/>
                        </a>
                        <a href="https://wa.me/79939538223" class="footer-main_social">
                            <whatsup/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer-border"></div>
            
        </div>
    </div>
    <div class="footer-outro">
                <span class="footer-outro_text">Дизайн и разработка 75.25 AGENCY</span>
                
                    <span class="footer-outro_text">Фото-видео контент 
                        <a href="https://ahmedovfilm.com">@AKHMEDOVFILM</a>
                    </span>
                
                <span class="footer-outro_text">ИП Дода Н. С.</span>
                <span class="footer-outro_text">2023, АЛЬТУРС</span>
            </div>
</template>


<script>
import telegram from '../../assets/icons/telegram.vue'
import whatsup from '../../assets/icons/whatsup.vue'

export default {
    components: {
        telegram,
        whatsup,

    },
    methods: {
        scrollToTop1() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 1200,
                    behavior: 'smooth',
                })
            } else {
                window.scrollTo({
                    top: 1550,
                    behavior: 'smooth',
                })
            }
        },
        scrollToTop2() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 1800,
                    behavior: 'smooth',
                })
            } else {
                window.scrollTo({
                    top: 2500,
                    behavior: 'smooth',
                })
            }
        },
        scrollToTop3() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 4500,
                    behavior: 'smooth',
                })
            } else {
                window.scrollTo({
                    top: 5080,
                    behavior: 'smooth',
                })
            }
        },

        scrollToHead() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        },
    }
}
</script>