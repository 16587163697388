<template>
    <div class="header" :class="mobi === true && 'active'">
        <div class="header-wrapper">
            <img src="../../assets/icons/AltursLogo.svg" alt="">
            <button class="mobileBtn" @click="isOpenBurger()" :class="mobi === true && 'active'">
                <img class="mobileBtn-pic" src="../../assets/burger.svg" alt="">
            </button>
        </div>
        <transition name="fake">
            <div class="header-nav" :class="mobi === true && 'active'">
                <div  @click="scrollToOne">
                    <span class="header-nav_text">групповые туры</span>
                </div>
                <div @click="scrollToTwo">
                    <span class="header-nav_text">индивидуальные туры</span>
                </div>
                <div @click="scrollToThree">
                    <span class="header-nav_text">процесс</span>
                </div>
                <div @click="scrollToFour">
                    <span class="header-nav_text">отзывы</span>
                </div>
                <div class="header-nav_wrapper">
                    <a href="https://t.me/al_tours" class="header-nav_button"> 
                        <telegram />
                    </a>
                    <a href="https://wa.me/79939538223" class="header-nav_button"> 
                        <whatsup />
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import telegram from '../../assets/icons/telegram.vue'
import whatsup from '../../assets/icons/whatsup.vue'

export default {
    components: {
        telegram,
        whatsup,

    },
    data() {
        return {
            mobi: false
        }
    },
    methods: {
        isOpenBurger() {
            this.mobi = !this.mobi
			setTimeout(() => {
				this.mobi = false
			}, 4000)
        },
        scrollToOne() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 1200,
                    behavior: 'smooth',
                }),
                this.mobi = false
            } else {
                window.scrollTo({
                    top: 1550,
                    behavior: 'smooth',
                })
            }
        },
        scrollToTwo() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 1800,
                    behavior: 'smooth',
                }),
                this.mobi = false
            } else {
                window.scrollTo({
                    top: 2500,
                    behavior: 'smooth',
                })
            }
        },
        scrollToThree() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 5300,
                    behavior: 'smooth',
                }),
                this.mobi = false
            } else {
                window.scrollTo({
                    top: 5900,
                    behavior: 'smooth',
                })
            }
        },
        scrollToFour() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.scrollTo({
                    top: 6580,
                    behavior: 'smooth',
                }),
                this.mobi = false
            } else {
                window.scrollTo({
                    top: 7430,
                    behavior: 'smooth',
                })
            }
        },
    },
}
</script>